import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase';
import { usePagination } from '../../pagination';
import Pagination from '../../pagination/pagination';
import BoardListItem from '../board/boardlistitem';
import './search.css';

export interface BoardItem {
  id: string;
  title: string;
  article: string;
  boardTitleImage: string | null;
  favoriteCount: number;
  commentCount: number;
  viewCount: number;
  createdAt: number;
  username: string;
  userId: string;
  writerProfileImage: string | null;
}

const Search: React.FC = () => {
  const { searchWord } = useParams<{ searchWord: string }>();
  const {
    currentPage,
    setCurrentPage,
    currentSection,
    setCurrentSection,
    viewList,
    setTotalList,
    viewPageList,
    totalSection,
  } = usePagination<BoardItem>(12);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "articles"), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);
      const articles: BoardItem[] = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data() as Omit<BoardItem, 'id'>;
        articles.push({
          id: doc.id,
          ...data,
        });
      });

      const filteredArticles = articles.filter(article =>
        article.title.toLowerCase().includes(searchWord?.toLowerCase() || '') ||
        article.article.toLowerCase().includes(searchWord?.toLowerCase() || '')
      );

      setTotalList(filteredArticles);
    };

    fetchData();
  }, [searchWord, setTotalList]);

  return (
    <div className="search-container">
      {viewList.length > 0 ? (
        <div className="search-grid">
          {viewList.map(article => (
            <BoardListItem key={article.id} {...article} />
          ))}
        </div>
      ) : (
        <div className="search-contents-nothing">검색 결과가 없습니다.</div>
      )}

      {viewList.length > 0 && (
        <div className="search-pagination-box">
          <Pagination
            currentPage={currentPage}
            currentSection={currentSection}
            setCurrentPage={setCurrentPage}
            setCurrentSection={setCurrentSection}
            viewPageList={viewPageList}
            totalSection={totalSection}
          />
        </div>
      )}
    </div>
  );
};

export default Search;
