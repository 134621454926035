/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import './style.css'
import MyPageLogOutButton from "./headerbutton";
import { useEffect, useRef, useState } from "react";
import defaultProfileImage from './profile.png'
import { auth } from "../firebase";
import { User } from "firebase/auth";
import searchIcon from "../assets/image/search-white.png";




const SearchButton = () => {
  const navigate = useNavigate();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // 폼의 기본 제출 이벤트를 방지
    const searchWord = searchInputRef.current?.value;
    if (searchWord) {
      navigate(`/search/${encodeURIComponent(searchWord)}`);
    }
  };

  return (
    <>
    <form className="search-form" onSubmit={handleSearch}>
      <div className="ssearch">
      <input
        type="text"
        className="search-input"
        ref={searchInputRef}
        placeholder="Search"
      />
      <button type="submit" className="search-button">
        <img src={searchIcon} alt="Search" className="search-icon" />
      </button>
      </div>
    </form>
    </>
  );
};

export default function Header() {
  const navigate = useNavigate();
  
  const [user, setUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState({
    displayName: '',
    photoURL: ''
  });





  const [isActive, setIsActive] = useState(false);
  const handleBurgerClick = () => {
    setIsActive(!isActive); // 토글 상태 변경
  };


  useEffect(() => {
    // Auth 상태 변경을 감지하는 리스너를 등록
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
      if (user) {
        setUserProfile({
          displayName: user.displayName || '',
          photoURL: user.photoURL || defaultProfileImage
        });
      } else {
        // 로그아웃 상태일 때의 처리, 예를 들어 로그인 페이지로 리디렉션 등
        setUserProfile({
          displayName: '',
          photoURL: ''
        });
        // navigate('/signin'); // 필요에 따라 사용
      }
    });

    // 컴포넌트가 언마운트될 때 리스너를 제거
    return () => unsubscribe();
  }, [navigate]);


  const onMyPageButtonClickHandler = () => {
    if(user && user.email) {
      navigate(`/user/${user.email}`);
    }
  };
  const onHomePageButtonClickHandler = () => {
      navigate(`/`);
  };




return (
<>
<header className="header">
  <div className="header-container">
    <div className="logo" onClick={onHomePageButtonClickHandler} >01Industries</div>
    <div className="header-right">
      <nav className="nav">
          <ul className={`nav-list ${isActive ? 'is-active' : ''}`}>
            <li className="nav-item"><a href="/project">Projects</a></li>
            <MyPageLogOutButton/>
          </ul>
      </nav>
      <SearchButton />

      {user &&
            <button className="user-profile-button" onClick={onMyPageButtonClickHandler}>
              <div className="nav-item">
                <img src={userProfile.photoURL || defaultProfileImage} alt="Profile" style={{ borderRadius: '50%', width: '30px', height: '30px' }} />
              </div>
            </button>
      }

        <div className={`hamburger ${isActive ? 'is-active' : ''}`} onClick={handleBurgerClick}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
    </div>
  </div>
</header>




</>
);
}