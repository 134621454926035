import { useNavigate } from 'react-router-dom';
import './style.css'

export default function Footer() {

    const navigate = useNavigate();

    
    const onInstaIconButtonClickHandler = () =>{
        window.open('https://www.instagram.com/tokamaklaboratory?igsh=YTQwZjQ0NmI0OA%3D%3D&utm_source=qr');
    };
    
    
    const onYoutubeIconButtonClickHandler = () =>{
        window.open('https://www.youtube.com/channel/UC9YFGyPzxACnyhrdP22Pr_w');
    };

    const onHomeButtonClickhandler = () => {
        navigate('/')
    } 
    
    
      return (
        <>
        <div className="footers">
            <div className="columns">
                <div className="column">
                    <div className="footer-logo-box" onClick={onHomeButtonClickhandler}>
                        <img alt="" width="30" height="30" src="/tokamak.png" />
                        <span className="footer-logo-text">01Industries</span>
                    </div>
                    <div className="footer-copyright">
                        Copyright ⓒ 2024 01Industries. All Rights Reserved.
                    </div>
                </div>
                <div className="column"></div>
                <div className="column">
                    <div className="footer-link-box">
                        <a href="mailto:wlghks3939@gmail.com" className="footer-email-link">
                            wlghks3939@gmail.com
                        </a>
                      <div className='iconbox'>  
                        <div className="icon-button" onClick={onInstaIconButtonClickHandler}>
                            <div className="insta-icon"></div>
                        </div>
                        <div className="icon-button" onClick={onYoutubeIconButtonClickHandler}>
                            <div className="youtube-icon"></div>
                        </div>
                      </div>
                      
                    </div>
                </div>
            </div>
        </div>

        </>
      )
    }