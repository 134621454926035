import MainTop from "./maintop";

export default function Main() {

    return (
        <>
        <MainTop/>
        </>
      )


}

