import { useEffect, useRef, useState } from 'react';
import './userprofile.css';
import defaultProfileImage from '../../assets/image/profile.png';
import { auth } from '../../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { updateProfile } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

export default function Userprofile() {
  const [isMyPage, setMyPage] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [isNicknameChange, setNicknameChange] = useState<boolean>(false);
  const [newNickname, setNewNickname] = useState<string>('');
  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const { email } = useParams();
  const user = auth.currentUser;
  const canWrite = user?.email === 'wlghks3939@gmail.com'; // 특정 이메일 권한 설정

  useEffect(() => {
    if (user && user.email === email) {
      setMyPage(true);
      setProfileImage(user.photoURL || defaultProfileImage);
      setNewNickname(user.displayName || '');
    } else {
      navigate('/signin');
    }
  }, [email, user, navigate]);

  const onProfileBoxClickHandler = () => {
    if (!isMyPage || !imageInputRef.current) return;
    imageInputRef.current.click();
  };

  const onNicknameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewNickname(e.target.value);
  };

  const onSaveNicknameChangeHandler = async () => {
    if (user) {
      try {
        await updateProfile(user, { displayName: newNickname });
        alert('닉네임이 변경되었습니다.');
        setNicknameChange(false);
      } catch (error) {
        console.error('닉네임 변경 오류:', error);
        alert('닉네임 변경 중 오류가 발생했습니다.');
      }
    }
  };

  const onProfileImageChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0] && user) {
      const file = e.target.files[0];
      const storage = getStorage();
      const storageRef = ref(storage, `profile/${user.uid}`);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const photoURL = await getDownloadURL(snapshot.ref);
        await updateProfile(user, { photoURL });
        setProfileImage(photoURL);
      } catch (error) {
        console.error('프로필 이미지 업로드 실패:', error);
        alert('프로필 이미지 업데이트 중 오류가 발생했습니다. 다시 시도해주세요.');
      }
    }
  };

  const onWriteClickHandler = () => {
    if (canWrite) {
      navigate('/write');
    } else {
      alert('글쓰기 권한이 없습니다.');
    }
  };

  return (
    <div className="user-profile-container">
      <div className="user-profile-header">
        <div className="profile-image-box" onClick={onProfileBoxClickHandler}>
          <div
            className="profile-image"
            style={{ backgroundImage: `url(${profileImage})` }}
          ></div>
          <input
            ref={imageInputRef}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={onProfileImageChangeHandler}
          />
        </div>
        <div className="user-info">
          {isMyPage ? (
            <>
              {isNicknameChange ? (
                <div className="nickname-edit">
                  <input
                    type="text"
                    value={newNickname}
                    onChange={onNicknameChangeHandler}
                  />
                  <button onClick={onSaveNicknameChangeHandler}>변경</button>
                </div>
              ) : (
                <h2>{user?.displayName}</h2>
              )}
              <button onClick={() => setNicknameChange(!isNicknameChange)}>
                닉네임 수정
              </button>
            </>
          ) : (
            <h2>{user?.displayName}</h2>
          )}
          <p>{user?.email}</p>
        </div>
      </div>
      <div className="write-button">
        {canWrite && <button onClick={onWriteClickHandler}>글쓰기</button>}
      </div>
    </div>
  );
}
