import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import './boardlistitem.css'; // CSS 파일 import
import { BoardItem } from './project';

export const BOARD_DETAIL_PATH = (docRefId: number | string) => `/detail/${docRefId}`;

export default function BoardListItem({ title, boardTitleImage, createdAt, id }: BoardItem) {
  const navigate = useNavigate();

  const getWriteDatetimeFormat = () => {
    const date = dayjs(createdAt);
    return date.format('YYYY.MM.DD');
  };

  const onClick = () => {
    navigate(BOARD_DETAIL_PATH(id));
  };

  return (
    <div className="card" onClick={onClick}>
      <div className="card-image-container">
        <img
          src={boardTitleImage || '/images/gray.png'} // 이미지 경로 수정
          alt="게시글 이미지"
          className="card-image"
        />
      </div>
      <div className="card-contents">
        <h3 className="card-title">{title}</h3>
        <p className="card-date">{getWriteDatetimeFormat()}</p>
      </div>
    </div>
  );
}
