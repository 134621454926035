import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./maintop.css"
import { useNavigate } from 'react-router-dom';

export default function MainTop () {

  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows : false,
    centerMode: true, // 센터 모드 활성화
  };

  const handleClick = () => {
    navigate('/project');
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
      <div className="slide-item">
          <img
            src={require('../../assets/image/pastel1.png')}
            alt="pixabay 1"
          />
          <div className="text-overlay">
            <h2>01Industries</h2>
            <p>We create something out of nothing.</p>
          </div>
        </div>
        <div className="slide-item">
          <img
            src={require('../../assets/image/pastel2.jpg')}
            alt="pixabay 2"
          />
          <div className="text-overlay">
            <h2>Projects</h2>
            <button onClick={handleClick}>view more</button>
          </div>
        </div>
      </Slider>
    </div>
  );
}