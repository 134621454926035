import { Route, Routes } from 'react-router-dom';
import Container from './layout/container';
import Main from './view/main/main';
import Signin from './view/user/signin';
import Write from './view/board/write';
import ProtectedRoute from './protected-route';
import BoardDetail from './view/board/boarddetail';
import Userprofile from './view/user/userprofile';
import Search from './view/search/search';
import Project from './view/board/project';


function App() {



  return (
    <Routes>
      <Route element={ <Container /> }>
        <Route path='/' element={<Main/>}/>
        <Route path='/signin' element={<Signin/>}/>
        <Route path="detail/:docRefId" element={<BoardDetail/>}/>
        <Route path="search/:searchWord" element={<Search/>}/>
        <Route path="/project" element={<Project/>}/>
        <Route path='/write' element={
        <ProtectedRoute>
        <Write/>
        </ProtectedRoute>
        }/>
        <Route path='/write/:docRefId' element={
          <ProtectedRoute>
            <Write />
          </ProtectedRoute>
        }/>
        <Route path="/user/:email" element={
        <ProtectedRoute>
        <Userprofile/>
        </ProtectedRoute>
        }/>
      </Route>
    </Routes>
  );
}

export default App;
