import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import './emblacarousel.css';

interface EmblaCarouselProps {
  slides: React.ReactNode[];
  title: string;
}

const EmblaCarousel: React.FC<EmblaCarouselProps> = ({ slides, title }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });

  return (
    <div className="embla-container">
      <div className="carousel-header">
        <h2 className="project-title">{title}</h2>
        <div className="carousel-buttons">
          <button
            className="embla__button embla__button--prev"
            onClick={() => emblaApi?.scrollPrev()}
          >
            ‹
          </button>
          <button
            className="embla__button embla__button--next"
            onClick={() => emblaApi?.scrollNext()}
          >
            ›
          </button>
        </div>
      </div>
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {slides.map((slide, index) => (
              <div className="embla__slide" key={index}>
                {slide}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmblaCarousel;


