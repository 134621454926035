import React, { useEffect, useState } from 'react';
import './project.css';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase';
import EmblaCarousel from './emblacarousel';
import BoardListItem from './boardlistitem';

export interface BoardItem {
  id: string;
  title: string;
  boardTitleImage?: string | null;
  createdAt: number;
  category?: string;
}

export default function Project() {
  const [articles, setArticles] = useState<BoardItem[]>([]);

  useEffect(() => {
    const q = query(collection(db, 'articles'), orderBy('createdAt', 'desc'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const articlesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as BoardItem[];
      setArticles(articlesData);
    });

    return () => unsubscribe();
  }, []);

  const topCategoryArticles = articles.filter((article) =>
    article.category === 'python' || article.category === 'algorithm'
  );

  const bottomCategoryArticles = articles.filter((article) =>
    !article.category || article.category === 'etc'
  );

  const topSlides = topCategoryArticles.map((article) => (
    <BoardListItem
      key={article.id}
      title={article.title}
      boardTitleImage={article.boardTitleImage}
      createdAt={article.createdAt}
      id={article.id}
    />
  ));

  const bottomSlides = bottomCategoryArticles.map((article) => (
    <BoardListItem
      key={article.id}
      title={article.title}
      boardTitleImage={article.boardTitleImage}
      createdAt={article.createdAt}
      id={article.id}
    />
  ));

  return (
    <>
      <div className="project-container1">
        {topSlides.length > 0 && (
          <div className="carousel-section">
            <EmblaCarousel 
              slides={topSlides} 
              title="Python&Algorithm" 
            />
          </div>
        )}
      </div>

      <div className="project-container1">
        {bottomSlides.length > 0 && (
          <div className="carousel-section">
            <EmblaCarousel 
              slides={bottomSlides} 
              title="Other Articles" 
            />
          </div>
        )}
      </div>
    </>
  );
}